import removeAccents from 'remove-accents'
import { replaceSpacesWithHyphens } from './strings'

/**
 * Removes special characters from a string
 * Characters to remove: - _ . ~ ! * ' ( ) ; : @ & = + $ , | / \ ? % # [ ]
 * @param str - Input string
 * @returns String without special characters
 */
const removeSpecialChars = (str: string): string => {
  return str.replace(/[-_\.~!*'();:@&=+$,|\/?%#\[\]\\]/g, '')
}

/**
 * Formats a string for use in URLs by:
 * 1. Removing special characters - _ . ~ ! * ' ( ) ; : @ & = + $ , | / \ ? % # [ ]
 * 2. Converting to lowercase
 * 3. Removing accents from characters
 * 4. Replacing spaces with hyphens
 * 5. Adding a trailing hyphen
 *
 * @example
 * formatTitleForUrl('Hello World!') // returns 'hello-world-'
 * formatTitleForUrl('Test@#$%') // returns 'test-'
 * formatTitleForUrl('Café & Restaurant') // returns 'cafe--restaurant-'
 * formatTitleForUrl('My (awesome) title') // returns 'my-awesome-title-'
 *
 * @param {string | undefined} title - The string to format
 * @returns {string} Formatted string safe for use in URLs, with trailing hyphen
 */
export const formatTitleForUrl = (title: string | undefined): string => {
  if (!title) return ''

  return `${replaceSpacesWithHyphens(removeSpecialChars(removeAccents(title.toLowerCase())))}-`
}

/**
 * Function get substring after last hyphens in string.
 * Used to get 'id' 93 from URL /category/sports-news-93
 * @param string
 */
export const getIdFromUrl = (string = ''): number | null => {
  const id = Number(string.split('-').pop())
  return id || null
}

/**
 * Get id and/or title from url like 'award-winner-3', 'award%20winner-3', 'award%20winner', 'award-winner', '3'
 * @param string
 */
export const getDataFromUrl = (
  string = ''
): { id: number | null; title: string } => {
  if (!string) return { id: null, title: '' }

  const parts = string.split('-')
  const lastPart = parts[parts.length - 1]
  const potentialId = Number(lastPart)

  // Check if last part is a valid number (including 0)
  if (!isNaN(potentialId) && lastPart !== '') {
    return {
      id: potentialId,
      title: parts.slice(0, -1).join('-'),
    }
  }

  return {
    id: null,
    title: string,
  }
}

export function getIdFromQuery(
  param: string,
  query?: URLSearchParams | undefined
): number | undefined {
  const id = query?.get(param)
  if (id == null) return undefined
  const idn = Number(id)
  if (isNaN(idn)) return undefined
  return idn
}
