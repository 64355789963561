import { ONE_HOUR_MS, ONE_MINUTE_MS } from '@/constants'
const envs: Record<string, string> = '__ENV__' in window ? window.__ENV__ : {}

// prettier-ignore
export const env = {
  UVO_CLIENT_HTTP_TIMEOUT: Number(envs.UVO_CLIENT_HTTP_TIMEOUT) || 10000,
  UVO_GTM_ID: envs.UVO_GTM_ID,
  UVO_FIREBASE_CONFIG: envs.UVO_FIREBASE_CONFIG || 'development',
  UVO_ROBOTS_TXT: envs.UVO_ROBOTS_TXT,
  UVO_SITEMAP_XML: envs.UVO_SITEMAP_XML,
  UVO_GOOGLE_PAL_ANYVIEW_PARAM_NAME: envs.UVO_GOOGLE_PAL_ANYVIEW_PARAM_NAME || 'AV_CUSTOM8', // TODO: remove after Pal bug will be fixed
  // This variable is used by debug adapter.
  // Could be changed ONLY via `DEBUG`
  UVO_CLIENT_ADAPTER_DEBUG: false,
  // This variable is used by Firebase SDK to set fetch interval.
  // By default = 1 minute for development environment and 1 hour for production
  UVO_CLIENT_FIREBASE_FETCH_INTERVAL:
    Number(envs.UVO_CLIENT_FIREBASE_FETCH_INTERVAL) ||
    (process.env.NODE_ENV === 'development' ? ONE_MINUTE_MS : ONE_HOUR_MS),
} as const
