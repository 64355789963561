import type { Tag, TvShow, TvShowEpisode } from '@setplex/tria-api'
import { MediaTypes } from '@setplex/tria-api'
import { format as formatCollection } from '../collections/format'
import { format as formatCreator } from '../creators/format'
import { format as formatTag } from '../tags/format'
import { ApiTag } from '../tags/index.h'
import type { ApiTvShow, ApiTvShowEpisode } from './index.h'

export const format = ({
  id,
  title,
  creator,
  isFavorite,
  poster,
  ageRating,
  userRating,
  languages,
  description,
  shortDescription,
  banner,
  releaseYears,
  seasonsCount,
  countries,
  genres,
  hashtags,
  crew,
  cast,
  ageRatingReasons,
}: ApiTvShow): TvShow => ({
  id,
  title,
  description,
  shortDescription,
  banner,
  creator: creator ? formatCreator(creator) : creator,
  isFavorite,
  poster,
  ageRating,
  userRating,
  releaseYears,
  seasonsCount,
  countries,
  languages:
    languages && languages.length
      ? languages.map(formatCollection)
      : (languages as any),
  genres: genres && genres.length ? genres.map(formatTag) : (genres as any),
  hashtags,
  crew,
  cast,
  ageRatingReasons,
  contentType: MediaTypes.TV_SHOW,
})

export const formatGenre = ({ id, title, content }: ApiTag): Tag => ({
  id: Number(id),
  title,
  content: content && content.length ? content.map(format) : (content as any),
})

export const formatEpisode = (episode: ApiTvShowEpisode): TvShowEpisode => {
  return {
    id: episode.id,
    ageRating: episode.ageRating,
    crew: episode.crew,
    cast: episode.cast,
    title: episode.title,
    description: episode.description,
    duration: episode.duration,
    banner: episode.banner,
    number: episode.number,
    seasonNumber: episode.seasonNumber,
    ratingReasons: episode.ratingReasons,
  }
}
