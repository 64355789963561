import { epgChannelsModel } from '$/epg/channels'
import { PATH } from '@/constants'
import { type LiveChannel } from '@setplex/tria-api'
import {
  combine,
  createEvent,
  createStore,
  restore,
  sample,
  type Store,
} from 'effector'
import { model as router } from '~/core/router'
import { model as session } from '~/entities/session'
import { lookbehind } from '~/shared/effector'
import { aye, T } from '~/shared/helpers'
import { QUERY_PARAM } from './index.h'

export const autoSetActiveId = createEvent<{
  channels: LiveChannel[]
  query: URLSearchParams
}>()

export const setActiveId = createEvent<number | null>()

export const $activeId = createStore<number | null>(null)
  .on(setActiveId, (_, id) => id)
  .on(autoSetActiveId, (current, { query }) => {
    const id = query?.get(QUERY_PARAM)
    if (id == null) return current // do not change current active id
    const idn = Number(id)
    if (isNaN(idn)) return current // do not change current active id
    return idn
  })

//
// persist active channel id in query string
//

export const pushActiveIdToQuery = createEvent<number | null>()

lookbehind({
  source: $activeId,
  clear: autoSetActiveId,
  within: 0,
  target: pushActiveIdToQuery,
})

sample({
  clock: pushActiveIdToQuery,
  source: router.$pathname,
  filter: (pathname, id) =>
    aye(pathname?.startsWith(`${PATH.LIVE}${PATH.CATEGORIES}`)) && Boolean(id),
  fn: (_, id) => ({ [QUERY_PARAM]: String(id) }),
  target: router.navigatePushQuery,
})

//
//
//

// currently active channel
export const $active: Store<LiveChannel | null> = combine(
  $activeId,
  epgChannelsModel.$all,
  (id, [channels]) => (id == null ? null : (channels.get(id) ?? null))
)

// select first channel from active list, if there is no channel id in url
sample({
  clock: autoSetActiveId,
  source: {
    id: $activeId,
    active: $active,
  },
  filter: ({ id, active }, { channels }) =>
    id == null && active == null && channels.length > 0,
  fn: (_, { channels }) => channels[0].id,
  target: setActiveId,
})

// load channel, if there is no active channel in loaded, but there is active channel id
sample({
  clock: autoSetActiveId,
  source: {
    id: $activeId,
    active: $active,
    pending: epgChannelsModel.loadOneFx.pending,
  },
  filter: ({ id, active, pending }) => id != null && active == null && !pending,
  fn: ({ id }) => id!,
  target: epgChannelsModel.loadOneFx,
})

// if there is still no active channel - reset channel id or select first channel from list
sample({
  clock: epgChannelsModel.loadOneFx.finally,
  source: {
    active: $active,
    autoset: restore(autoSetActiveId, null),
  },
  filter: ({ active }) => active == null,
  fn: ({ autoset }) =>
    autoset != null && autoset.channels.length > 0
      ? autoset.channels[0].id
      : null,
  target: setActiveId,
})

// store that indicates if active channel is ready to be displayed
// becomes true when channels are loaded and active channel is set
export const $activeChannelIsReady = createStore<boolean>(false).reset(
  session.$isAuthenticated
)

const $isEpgPage = router.$location.map(
  (location) =>
    location?.pathname.includes(`${PATH.LIVE}${PATH.CATEGORIES}`) ?? false
)
// set active channel ready when:
// - active channel is set manually
// - active channel is set automatically
// - all channels are loaded
sample({
  clock: [setActiveId, autoSetActiveId, epgChannelsModel.$allIsReady],
  source: {
    allIsReady: epgChannelsModel.$allIsReady,
    isEpgPage: $isEpgPage,
  },
  // only set ready if channels are actually loaded and this flag is only set on epg page (to avoid setting when open simple live player)
  filter: ({ allIsReady, isEpgPage }) => {
    return Boolean(allIsReady && isEpgPage)
  },
  fn: T,
  target: $activeChannelIsReady,
})
