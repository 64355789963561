import { createEffect } from 'effector'
import { stateful } from '../../lib/stateful'
import type { Content } from '../content'
import { TvShow } from '../tvshows'
import type {
  Creator,
  CreatorAwards,
  CreatorSocialMediaLink,
  CreatorTag,
} from './index.h'

export type { Creator, CreatorAwards, CreatorSocialMediaLink, CreatorTag }

type PageParams = { limit?: number; offset?: number }
type SortParams = { by?: string; order?: string }
type FilterParams = { q?: string }
type GetManyParams = PageParams & SortParams & FilterParams
type GetOneParams = { id: number }
type CreatorParams = { id?: number }
type GenreParams = { genreId?: number }
type ContentParams = { withContent?: boolean; contentCount?: number }
type CreatorContentParams = CreatorParams &
  ContentParams &
  PageParams &
  SortParams

// *
// * base API effects
// *

export const base = {
  getManyFx: createEffect<GetManyParams, Creator[]>(),
  getOneFx: createEffect<GetOneParams, Creator>(),
  getContentFx: createEffect<
    CreatorParams & PageParams & SortParams,
    Content[]
  >(),
  getTvShowsFx: createEffect<
    CreatorParams & PageParams & SortParams,
    TvShow[]
  >(),
  getContentTagsFx: createEffect<
    CreatorParams & ContentParams & PageParams & SortParams,
    CreatorTag[]
  >(),
  getTvShowTagsFx: createEffect<
    CreatorParams & PageParams & SortParams,
    CreatorTag[]
  >(),
  getTvShowsByTagFx: createEffect<
    GenreParams & CreatorParams & PageParams & SortParams,
    TvShow[]
  >(),
}

// *
// *
// *

export const pageable = (initial?: GetManyParams) =>
  stateful({
    effect: base.getManyFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ by, order }: SortParams) => ({ by, order }),
      by: (by: string | undefined) => ({ by }),
      order: (order: string | undefined) => ({ order }),
      filter: (q: string | undefined) => ({ q }),
    },
  })

export const pageableContent = (initial?: CreatorContentParams) =>
  stateful({
    effect: base.getContentTagsFx,
    initial,
    methods: {
      limit: ({ id, n }) => ({ id, limit: n }),
      offset: ({ id, n }) => ({ id, offset: n }),
      next: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: offset + limit,
      }),
      prev: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ id, by, order }) => ({ id, by, order }),
      by: ({ id, by }) => ({ id, by }),
      order: ({ id, order }) => ({ id, order }),
    },
  })

export const pageableCreatorContent = (initial?: CreatorContentParams) =>
  stateful({
    effect: base.getContentFx,
    initial,
    methods: {
      limit: ({ id, n }) => ({ id, limit: n }),
      offset: ({ id, n }) => ({ id, offset: n }),
      next: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: offset + limit,
      }),
      prev: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ id, by, order }) => ({ id, by, order }),
      by: ({ id, by }) => ({ id, by }),
      order: ({ id, order }) => ({ id, order }),
    },
  })

export const pageableCreatorTvShows = (initial?: CreatorContentParams) =>
  stateful({
    effect: base.getTvShowsFx,
    initial,
    methods: {
      limit: ({ id, n }) => ({ id, limit: n }),
      offset: ({ id, n }) => ({ id, offset: n }),
      next: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: offset + limit,
      }),
      prev: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ id, by, order }) => ({ id, by, order }),
      by: ({ id, by }) => ({ id, by }),
      order: ({ id, order }) => ({ id, order }),
    },
  })

export const pageableTvShowTags = (initial?: CreatorContentParams) =>
  stateful({
    effect: base.getTvShowTagsFx,
    initial,
    methods: {
      limit: ({ id, n }) => ({ id, limit: n }),
      offset: ({ id, n }) => ({ id, offset: n }),
      next: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: offset + limit,
      }),
      prev: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ id, by, order }) => ({ id, by, order }),
      by: ({ id, by }) => ({ id, by }),
      order: ({ id, order }) => ({ id, order }),
    },
  })

export const pageableTvShowByTag = (initial?: CreatorContentParams) =>
  stateful({
    effect: base.getTvShowsByTagFx,
    initial,
    methods: {
      limit: ({ id, n }) => ({ id, limit: n }),
      offset: ({ id, n }) => ({ id, offset: n }),
      next: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: offset + limit,
      }),
      prev: (_: void, { id, offset = 0, limit = 36 }) => ({
        id,
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ id, by, order }) => ({ id, by, order }),
      by: ({ id, by }) => ({ id, by }),
      order: ({ id, order }) => ({ id, order }),
    },
  })
