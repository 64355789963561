import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { format, formatEpisode, formatGenre } from './format'
import type {
  ApiTvShow,
  ApiTvShowEpisodes,
  ApiTvShowSeason,
  ApiTvShowsGenres,
} from './index.h'

export function use(
  http: HttpClient,
  tvshows: API['tvshows'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // GET /api/web/tvshows/genres
  tvshows.base.getTvShowsGenresFx.use(async ({ limit, offset }) => {
    const params = query({
      limit,
      offset,
    })
    const json = await http.get<
      GenericApiResponse<{ results: ApiTvShowsGenres; total: number }>
    >(`/tvshows/genres${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in tvshows.base.getTvShowsGenresFx')
    }

    const results = (json.payload.results || []).map(formatGenre)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/tvshows/by-genre/{genreId}
  tvshows.base.getTvShowsByGenreFx.use(async ({ limit, offset, id }) => {
    const params = query({
      limit,
      offset,
    })
    const json = await http.get<
      GenericApiResponse<{ results: ApiTvShow[]; total: number }>
    >(`/tvshows/by-genre/${id}${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in tvshows.base.getTvShowsByGenreFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/tvshows/${id}
  tvshows.base.getTvShowFx.use(async ({ id }) => {
    const json = await http.get<GenericApiResponse<ApiTvShow>>(`/tvshows/${id}`)
    // Example of object to pass to format function

    if (!json || !json.payload) {
      throw new Error('Empty answer in tvshows.base.getTvShowFx')
    }

    return format(json.payload)
  })

  // GET /api/web/tvshows/${id}/seasons
  tvshows.base.getTvShowSeasonsByIdFx.use(
    async ({ id, withEpisodeCounts = false }) => {
      const params = query({
        withEpisodeCounts,
      })
      const json = await http.get<GenericApiResponse<ApiTvShowSeason[]>>(
        `/tvshows/${id}/seasons${params}`
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in tvshows.base.getTvShowSeasonsByIdFx')
      }

      return json.payload
    }
  )

  // GET /api/web/tvshows/{id}/seasons/{seasonId}/episodes
  tvshows.base.getTvShowEpisodesBySeasonIdFx.use(
    async ({ id, seasonId, limit, offset }) => {
      const params = query({
        limit,
        offset,
      })
      const json = await http.get<
        GenericApiResponse<{ results: ApiTvShowEpisodes; total: number }>
      >(`/tvshows/${id}/seasons/${seasonId}/episodes${params}`)

      if (!json || !json.payload) {
        throw new Error(
          'Empty answer in tvshows.base.getTvShowEpisodesBySeasonIdFx'
        )
      }

      const results = (json.payload.results || []).map(formatEpisode)

      return Object.defineProperty(results, 'total', {
        value: json.payload.total ?? 0,
        writable: false,
      })
    }
  )

  // GET /api/web/tvshows/{id}/seasons/{seasonId}/episodes/{episodeId}/url
  tvshows.base.getOneTvShowEpisodeUrlPlaybackFx.use(
    async ({ id, seasonId, episodeId, headers = {} }) => {
      const json = await http.get<GenericApiResponse<{ url: string }>>(
        `/tvshows/${id}/seasons/${seasonId}/episodes/${episodeId}/url`,
        {
          headers,
        }
      )

      if (!json || !json.payload) {
        throw new Error(
          'Empty answer in tvshows.base.getOneTvShowEpisodeUrlPlaybackFx'
        )
      }

      return json.payload
    }
  )

  // GET /api/web/tvshows/by-language/{languageId}
  tvshows.base.getTvShowsByCollectionFx.use(async ({ limit, offset, id }) => {
    const params = query({
      limit,
      offset,
    })
    const json = await http.get<
      GenericApiResponse<{ results: ApiTvShow[]; total: number }>
    >(`/tvshows/by-language/${id}${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in tvshows.base.getTvShowsByCollectionFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/tvshows/by-hashtag/{hashtagId}
  tvshows.base.getTvShowsByHashtagFx.use(async ({ limit, offset, id }) => {
    const params = query({
      limit,
      offset,
    })
    const json = await http.get<
      GenericApiResponse<{ results: ApiTvShow[]; total: number }>
    >(`tvshows/by-hashtag/${id}${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in tvshows.base.getTvShowsByHashtagFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // GET /api/web/tvshows/my-list
  tvshows.base.getFavoritesFx.use(async ({ limit, offset }) => {
    const params = query({
      limit,
      offset,
    })

    const json = await http.get<
      GenericApiResponse<{ results: ApiTvShow[]; total: number }>
    >(`/tvshows/my-list${params}`)

    if (!json || !json.payload) {
      throw new Error('Empty answer in live.base.getFavoritesFx')
    }

    const results = (json.payload.results || []).map(format)

    return Object.defineProperty(results, 'total', {
      value: json.payload.total ?? 0,
      writable: false,
    })
  })

  // POST /api/web/tvshows/my-list/{id}/add
  tvshows.base.addToFavoritesFx.use(async ({ id }) => {
    const json = await http.post<GenericApiResponse<{}>>(
      `/tvshows/my-list/${id}/add`
    )
    if (!json) {
      throw new Error('Empty answer in tvshows.base.addToFavoritesFx')
    }
  })

  // POST /api/web/tvshows/my-list/{id}/remove
  tvshows.base.removeFromFavoritesFx.use(async ({ id }) => {
    const json = await http.post<GenericApiResponse<{}>>(
      `/tvshows/my-list/${id}/remove`
    )
    if (!json) {
      throw new Error('Empty answer in tvshows.base.removeFromFavoritesFx')
    }
  })

  tvshows.base.getSeasonsWithEpisodesFx.use(async ({ id }) => {
    const getEpisodes = async ({
      seasonId,
      id,
    }: {
      seasonId?: number
      id?: number
    }) =>
      await tvshows.base.getTvShowEpisodesBySeasonIdFx({
        seasonId,
        id,
      })

    const seasons = await tvshows.base.getTvShowSeasonsByIdFx({
      id,
    })

    const promises = seasons.map(async (season) => {
      const episodes = await getEpisodes({ seasonId: season.id, id })
      return {
        ...season,
        episodes: episodes.map(formatEpisode),
      }
    })

    return await Promise.all(promises)
  })
}
