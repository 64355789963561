import {
  formatDateToString,
  formatTimeToString,
  getIdFromUrl,
  objToStringBase64,
  random,
} from '@/helpers'
import { runtime } from '~/app/environment'
import { PATH } from '~/shared/constants/path'

/**
 * Function adds '/play', removes entity title.
 * For example, function will return url 'https://any.com/movie/486/play' if window.location.href was https://any.com/movie/power-saw-486
 */
export const getPageUrl = (): string => {
  const { pathname = '', search = '' } = new URL(window.location.href)
  let pageUrl = ''
  const h = pathname.replace(/\/play$/, '')
  const id = getIdFromUrl(h) || Number(h.split('/').pop()) || null
  /* Handle urls like /movie/movie-title-1796/play?{search}, /movie/1796/play, /movie/movie-title-1796, /movie/1796 */
  if (pathname.includes(PATH.MOVIE)) {
    pageUrl = id
      ? `${window.location.origin}${PATH.MOVIE}/${id}${PATH.PLAY}${search}`
      : ''
  }

  if (pathname.includes(PATH.LIVE)) {
    pageUrl = id
      ? `${window.location.origin}${PATH.LIVE}/${id}${PATH.PLAY}${search}`
      : ''
  }

  if (pathname.includes(PATH.TV_SHOW)) {
    pageUrl = id
      ? `${window.location.origin}${PATH.TV_SHOW}/${id}${PATH.PLAY}${search}`
      : ''
  }

  return pageUrl
}

export const getMacrosHeader = (nonce?: string): string => {
  const requestRandom = random(1, 99999999) // random number generated  Length <= 8
  const now = new Date()

  const date = formatDateToString(now)
  const time = formatTimeToString(now)

  const header = {
    requestRandom,
    app: {
      name: runtime.app.name,
      adv: {
        doTrack: 1,
        doNotTrack: 0,
      },
    },
    player: {
      inview: '1', // always 1
      inviewPerc: '100', // always 100
    },
    system: {
      date, //  Current date in the format yyyy-mm-dd
      time, //  Current time in the format hh-mm-ss
    },
    gPalKey: nonce || '',
    pageUrl: getPageUrl(),
  }

  return objToStringBase64(header)
}
