import { createEffect, createStore } from 'effector'
import type { ApiResponseError } from '../../interfaces/errors'
import { stateful } from '../../lib/stateful'
import type {
  TvShow,
  TvShowEpisode,
  TvShowSeason,
  TvShowsGenre,
  TvShowsPlaylist,
} from './index.h'

export type {
  TvShow,
  TvShowEpisode,
  TvShowSeason,
  TvShowsGenre,
  TvShowsPlaylist,
}

type GetOneParams = { id?: number }
export type PlaybackUrl = { url: string }

type RequestHeaders = { headers: Headers }

type FavoriteParams = {
  id: number
  contentTitle?: string
  contentUrl?: string
  isFavorite: boolean
  creatorId?: string
}

type PageParams = { limit?: number; offset?: number }
type SeasonParams = { seasonId?: number }
type EpisodeParams = { episodeId?: number }
type WithEpisodeCountsParams = { withEpisodeCounts?: boolean }

export interface TvShowsGenres extends Array<TvShowsGenre> {
  total: number
}

export interface TvShowEpisodes extends Array<TvShowEpisode> {
  total: number
}

// *
// * base API effects
// *
export const base = {
  getTvShowsGenresFx: createEffect<PageParams, TvShowsGenres>(),
  getTvShowsByGenreFx: createEffect<GetOneParams & PageParams, TvShow[]>(),
  getTvShowFx: createEffect<GetOneParams, TvShow>(),
  getTvShowSeasonsByIdFx: createEffect<
    GetOneParams & WithEpisodeCountsParams,
    TvShowSeason[]
  >(),
  getTvShowEpisodesBySeasonIdFx: createEffect<
    PageParams & SeasonParams & GetOneParams,
    TvShowEpisodes
  >(),
  getOneTvShowEpisodeUrlPlaybackFx: createEffect<
    GetOneParams & SeasonParams & EpisodeParams & RequestHeaders,
    PlaybackUrl
  >(),
  getTvShowsByCollectionFx: createEffect<GetOneParams & PageParams, TvShow[]>(),
  getTvShowsByHashtagFx: createEffect<GetOneParams & PageParams, TvShow[]>(),
  getFavoritesFx: createEffect<PageParams, TvShow[]>(),
  addToFavoritesFx: createEffect<FavoriteParams, void>(),
  removeFromFavoritesFx: createEffect<FavoriteParams, void>(),
  getSeasonsWithEpisodesFx: createEffect<GetOneParams, TvShowsPlaylist>(),
}

export const pageableGenres = (initial?: PageParams) =>
  stateful({
    effect: base.getTvShowsGenresFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableByGenre = (initial?: PageParams) =>
  stateful({
    effect: base.getTvShowsByGenreFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableEpisodes = (initial?: PageParams) =>
  stateful({
    effect: base.getTvShowEpisodesBySeasonIdFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableByCollection = (initial?: PageParams) =>
  stateful({
    effect: base.getTvShowsByCollectionFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableByHashtag = (initial?: PageParams) =>
  stateful({
    effect: base.getTvShowsByHashtagFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableFavorites = (initial?: PageParams) =>
  stateful({
    effect: base.getFavoritesFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void) => ({}),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const error = {
  getTvShowFx: createStore<null | ApiResponseError>(null).on(
    base.getTvShowFx.fail,
    (_, { error }) => {
      // @ts-ignore because error does not exist in type Error
      const response = error?.error?.response
      if (!response) return

      return {
        code: response.status,
        message: response.statusText,
      }
    }
  ),
}
