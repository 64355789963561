import type { Adapter, API } from '@setplex/tria-api'
import { http } from './http'
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_BY,
  DEFAULT_SORT_ORDER,
  type AdapterDefaults,
} from './index.h'

import { use as analytics } from './entities/analytics'
import { use as session } from './entities/auth/session'
import { use as user } from './entities/auth/user'
import { use as carousels } from './entities/carousels'
import { use as collections } from './entities/collections'
import { use as content } from './entities/content'
import { use as creators } from './entities/creators'
import { use as home } from './entities/home'
import { use as live } from './entities/live'
import { use as onboarding } from './entities/onboarding'
import { use as otherTag } from './entities/otherTag'
import { use as privacy } from './entities/privacy'
import { use as profile } from './entities/profile'
import { use as users } from './entities/profile/users'
import { use as search } from './entities/search'
import { use as support } from './entities/support'
import { use as tags } from './entities/tags'
import { use as tvshows } from './entities/tvshows'

export const version: string = '__VERSION__'

export { setLogger } from './utils/logger'

export const adapter: Adapter = (
  api: API,
  defaults: AdapterDefaults = {
    limit: DEFAULT_PAGE_SIZE,
    sortBy: DEFAULT_SORT_BY,
    sortOrder: DEFAULT_SORT_ORDER,
  }
) => {
  const client = http(api.fx)

  analytics(client, api.analytics_ovp, api, defaults)
  carousels(client, api.carousels, api, defaults)
  collections(client, api.collections, api, defaults)
  tags(client, api.tags, api, defaults)
  content(client, api.content, api, defaults)
  live(client, api.live, api, defaults)
  creators(client, api.creators, api, defaults)
  search(client, api.search, api, defaults)
  user(client, api.user, api)
  session(client, api.userSession, api)
  profile(client, api.profile, api)
  users(client, api.users, api)
  privacy(client, api.privacy, api)
  support(client, api.support, api)
  home(client, api.home, api)
  otherTag(client, api.otherTag, api)
  onboarding(client, api.onboarding, api)
  tvshows(client, api.tvshows, api, defaults)
}
