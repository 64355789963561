/**
 * Function adds query params to url
 * @param url
 * @param size
 * @param fmt
 */

type Format = 'webp' | 'png'
export const formatUrl = ({
  url,
  size,
  fmt,
}: {
  url: string
  size?: string
  fmt?: Format
}): string => {
  if (!url) return ''

  let str: string = ''
  if (size) {
    str = `resize=${size}`
  }
  if (fmt) {
    const f = `format=${fmt}`
    str += str ? '&' + f : f
  }

  return str ? url + '?' + str : url
}

export const formatBannerUrl = (url: string): string =>
  formatUrl({
    url,
    size: '1920x1080',
    fmt: 'webp',
  })

export const formatCardPosterUrl = (url: string): string =>
  formatUrl({
    url,
    size: '436x658',
    fmt: 'webp',
  })

export const formatCardAvatarUrl = (url: string): string =>
  formatUrl({
    url,
    size: '100x100',
    fmt: 'webp',
  })

export const formatChannelLogoUrl = (url: string): string =>
  formatUrl({
    url,
    size: '200x200',
    fmt: 'webp',
  })

export const formatPlayerCardPosterUrl = (url: string): string =>
  formatUrl({
    url,
    size: '280x157.5',
    fmt: 'webp',
  })
